var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Client } from "hub-lib/client/client.bin";
import { mm_Advertisers } from "hub-lib/models/orientdb/mm_Advertisers.bin";
import { mm_Brands } from "hub-lib/models/orientdb/mm_Brands.bin";
import { mm_Products } from "hub-lib/models/orientdb/mm_Products.bin";
import { ReferentialHasViews } from "hub-lib/models/orientdb/ReferentialHasViews.bin";
import { ref_Agencies } from "hub-lib/models/orientdb/ref_Agencies.bin";
import { ref_AgencyGroups } from "hub-lib/models/orientdb/ref_AgencyGroups.bin";
import { ref_Companies } from "hub-lib/models/orientdb/ref_Companies.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_Supports } from "hub-lib/models/orientdb/ref_Supports.bin";
import { src_AdwOne } from "hub-lib/models/orientdb/src_AdwOne.bin";
import { src_CODIPRESSE } from "hub-lib/models/orientdb/src_CODIPRESSE.bin";
import { src_MM } from "hub-lib/models/orientdb/src_MM.bin";
import { rmAccents } from "hub-lib/tools.bin";
import { loadingGridRef, loadingGridView, loadingLinks, setState } from "../../../../redux/linkMgrSlice";
import { store } from "../../../../redux/store";
import { ConsoleDebug, PrintMesureTime, StartMeasureTime } from "../../../../utils/localstorage.bin";
import { src_TSM } from "hub-lib/models/orientdb/src_TSM.bin";
import { src_MediaSpecs } from "hub-lib/models/orientdb/src_MediaSpecs.bin";
var sources = [src_AdwOne.name, src_CODIPRESSE.name, src_TSM.name, src_MM.name, src_MediaSpecs.name];
var delaySearchModality;
var indexerLinksBySource = {};
var indexerAllLinksBySource = {};
var indexerAllLinks = {};
var indexerLinks = {};
var indexerLinksOneWay = {};
export var MappingProperties = { "Format": ["HasFormat"], "Emplacement": ["HasPlacement", "HasEmplacement"], "Couleur": ["HasCouleur"], "Rubrique": ["HasRubrique"], "Implantation": ["HasImplantation"], "Majoration": ["HasMajoration"] };
export function getProp(edge, propname) { for (var idx = 0; idx < edge.properties.length; idx++) {
    var prop = edge.properties[idx];
    if (prop.name === propname)
        return prop;
} return undefined; }
export function getDimension(edge) { return getProp(edge, "Referential"); }
export function getView(edge) { return getProp(edge, "out"); }
export function getSource(edge) { return getProp(edge, "in"); }
export var getLinkFromName = function (links, dim, selectedSource) { if (!selectedSource) {
    console.log("No selected source");
    return undefined;
} for (var _i = 0, links_1 = links; _i < links_1.length; _i++) {
    var link = links_1[_i];
    var dimLink = getDimension(link);
    var source = getSource(link);
    if (dimLink && source && dimLink.linkedClass === dim && source.linkedClass === selectedSource["@class"])
        return link;
} return undefined; };
export var getNbLinks = function (selectedLink, source) { if (selectedLink) {
    var _indexerLinksBySource, _indexerLinksBySource2, _indexerLinksBySource3;
    var key = selectedLink.name;
    if (key && source)
        return Object.keys((_indexerLinksBySource = (_indexerLinksBySource2 = indexerLinksBySource[source]) === null || _indexerLinksBySource2 === void 0 ? void 0 : (_indexerLinksBySource3 = _indexerLinksBySource2[key]) === null || _indexerLinksBySource3 === void 0 ? void 0 : _indexerLinksBySource3["Views"]) !== null && _indexerLinksBySource !== void 0 ? _indexerLinksBySource : {}).length;
} return 0; };
export var getTotalNbLinks = function (selectedLink, source) { if (selectedLink) {
    var _indexerAllLinksBySou, _indexerAllLinksBySou2;
    var key = selectedLink.name;
    if (key && source)
        return Object.keys((_indexerAllLinksBySou = (_indexerAllLinksBySou2 = indexerAllLinksBySource[source]) === null || _indexerAllLinksBySou2 === void 0 ? void 0 : _indexerAllLinksBySou2[key]) !== null && _indexerAllLinksBySou !== void 0 ? _indexerAllLinksBySou : {}).length;
} return 0; };
export var isLinked = function (element, baseClassName) { return isRidLinked(element === null || element === void 0 ? void 0 : element["@rid"], baseClassName); };
export var isRidLinked = function (rid, baseClassName) {
    var _a = store.getState().linkMgr, selectedLink = _a.selectedLink, selectedSource = _a.selectedSource;
    if (selectedLink) {
        var key = selectedLink.name;
        var id = rid; //if (key && id && indexerLinks[key] && indexerLinks[key][id]) return true;
        if (key && id) {
            var _indexerLinksBySource6, _indexerLinksBySource7, _indexerLinksBySource8, _indexerLinksBySource9;
            if (baseClassName) {
                var _indexerLinksBySource4, _indexerLinksBySource5;
                if ((_indexerLinksBySource4 = indexerLinksBySource[selectedSource["@rid"]]) !== null && _indexerLinksBySource4 !== void 0 && (_indexerLinksBySource5 = _indexerLinksBySource4[key]) !== null && _indexerLinksBySource5 !== void 0 && _indexerLinksBySource5[baseClassName].includes(id))
                    return true;
            }
            else if ((_indexerLinksBySource6 = indexerLinksBySource[selectedSource["@rid"]]) !== null && _indexerLinksBySource6 !== void 0 && (_indexerLinksBySource7 = _indexerLinksBySource6[key]) !== null && _indexerLinksBySource7 !== void 0 && _indexerLinksBySource7["Views"].includes(id) || (_indexerLinksBySource8 = indexerLinksBySource[selectedSource["@rid"]]) !== null && _indexerLinksBySource8 !== void 0 && (_indexerLinksBySource9 = _indexerLinksBySource8[key]) !== null && _indexerLinksBySource9 !== void 0 && _indexerLinksBySource9["Referentials"].includes(id))
                return true;
        }
    }
    return false;
};
export var iconLinked = function (d) { return isLinked(d) ? "link" : "link_off"; };
export var refClassname = function (d) { var inId = store.getState().linkMgr.inId; var base = (d === null || d === void 0 ? void 0 : d["Active"]) === false ? "is-inactive " : ""; if (inId === d["@rid"])
    return base + "is-selected"; if (isLinked(d))
    return base + "is-linked"; return base; };
export var getLinkFromIn = function (inId) { return __awaiter(void 0, void 0, void 0, function () { var _a, selectedLink, selectedSource, link; return __generator(this, function (_b) {
    switch (_b.label) {
        case 0:
            if (!inId) return [3 /*break*/, 2];
            _a = store.getState().linkMgr, selectedLink = _a.selectedLink, selectedSource = _a.selectedSource;
            if (!selectedLink) return [3 /*break*/, 2];
            return [4 /*yield*/, Client.searchVertex(selectedLink.name, { Referential: inId, in: selectedSource["@rid"] })];
        case 1:
            link = _b.sent();
            if (link && link.data.results && link.data.results.length)
                return [2 /*return*/, link.data.results.map(function (_link) { return _link.out; })];
            _b.label = 2;
        case 2: return [2 /*return*/, []];
    }
}); }); };
export var ClickRefModalityHandler = function (_event) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, inId, modalitiesViews, outIds, outIdsAuto, d, outIds_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = store.getState().linkMgr, inId = _a.inId, modalitiesViews = _a.modalitiesViews, outIds = _a.outIds, outIdsAuto = _a.outIdsAuto;
                d = _event.dataItem;
                if (!(inId === d["@rid"])) return [3 /*break*/, 1];
                store.dispatch(setState({ inId: undefined, outIds: [], outIdsAuto: [], topModalitiesViews: undefined }));
                return [3 /*break*/, 3];
            case 1: return [4 /*yield*/, getLinkFromIn(d["@rid"])];
            case 2:
                outIds_1 = _b.sent();
                store.dispatch(setState({ inId: d["@rid"], outIds: outIds_1, outIdsAuto: [], topModalitiesViews: modalitiesViews === null || modalitiesViews === void 0 ? void 0 : modalitiesViews.filter(function (m) { return outIds_1.includes(m["@rid"]); }) }));
                _b.label = 3;
            case 3:
                if (outIds !== null && outIds !== void 0 && outIds.some(function (id) { return !isRidLinked(id); }) || outIdsAuto !== null && outIdsAuto !== void 0 && outIdsAuto.length)
                    UpdateFilteredModalityViews(100);
                return [2 /*return*/];
        }
    });
}); };
export var getModalities = function (modalities) { var selectedLink = store.getState().linkMgr.selectedLink; if (!modalities)
    return []; if (!selectedLink)
    return []; var propertyOut = "out_" + selectedLink.name; modalities === null || modalities === void 0 ? void 0 : modalities.forEach(function (m) { var _m$Label; return m["LabelLower"] = (_m$Label = m.Label) === null || _m$Label === void 0 ? void 0 : _m$Label.toLocaleLowerCase(); }); return modalities.filter(function (m) { return m[propertyOut]; }); };
var filterView = function (modality, searchLower) {
    var _a;
    var _rmAccents, _m$LabelLower;
    var _b = store.getState().linkMgr.filtersView, includeLinkedView = _b.includeLinkedView, includeUnlinkedView = _b.includeUnlinkedView, excludeInactiveView = _b.excludeInactiveView, minDateViewUpdate = _b.minDateViewUpdate, filterViewUpdate = _b.filterViewUpdate; // const { outIds } = store.getState().linkMgr;
    var m = modality;
    var res = (_rmAccents = rmAccents((_m$LabelLower = m["LabelLower"]) === null || _m$LabelLower === void 0 ? void 0 : _m$LabelLower.toLowerCase())) === null || _rmAccents === void 0 ? void 0 : _rmAccents.includes(rmAccents(searchLower));
    var dico = (_a = {}, _a[mm_Advertisers.name] = "MD_parentGroupId", _a[mm_Brands.name] = "MD_advertiserIds", _a[mm_Products.name] = "MD_brandIds", _a);
    if (dico[m["@class"]]) {
        if (m["@class"] === mm_Advertisers.name) {
            res = res || m[dico[m["@class"]]] === searchLower;
        }
        else {
            var _m$dico$m$Class;
            res = res || ((_m$dico$m$Class = m[dico[m["@class"]]]) === null || _m$dico$m$Class === void 0 ? void 0 : _m$dico$m$Class.map(function (id) { return id.toString(); }).includes(searchLower));
        }
    }
    res = res || m["ExternalID"] == searchLower;
    if (!includeLinkedView)
        res = res && !isLinked(modality);
    if (!includeUnlinkedView)
        res = res && isLinked(modality);
    if (excludeInactiveView)
        res = res && (!modality.hasOwnProperty("MD_status") || modality["MD_status"] == "Active");
    if (filterViewUpdate)
        res = res && (!modality.hasOwnProperty("MD_upsertDate") && !modality.hasOwnProperty("TSM_upsertDate") || new Date(modality["MD_upsertDate"]) >= minDateViewUpdate || new Date(modality["TSM_upsertDate"]) >= minDateViewUpdate);
    return res;
};
export function GetFilteredModalitiesViews(_modalitiesViews) { var searchView = store.getState().linkMgr.filtersView.searchView; var modalitiesViews = store.getState().linkMgr.modalitiesViews; var toFilter = _modalitiesViews !== null && _modalitiesViews !== void 0 ? _modalitiesViews : modalitiesViews; var searchLower = searchView.toLowerCase(); return toFilter === null || toFilter === void 0 ? void 0 : toFilter.filter(function (v) { return filterView(v, searchLower); }); }
export var UpdateFilteredModalityViews = function () { var delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1000; ConsoleDebug("UpdateFilteredModalityViews, delay ".concat(delay)); store.dispatch(loadingGridView(true)); var updateCb = function () { var filteredModalitiesViews = GetFilteredModalitiesViews(); store.dispatch(setState({ filteredModalitiesViews: filteredModalitiesViews })); store.dispatch(loadingGridView(false)); }; if (delay) {
    clearTimeout(delaySearchModality);
    delaySearchModality = setTimeout(updateCb, delay);
}
else {
    updateCb();
} };
export function capitalizeFirstLetter(string) { return string.charAt(0).toUpperCase() + string.slice(1); }
export var viewClassname = function (d) { var _a = store.getState().linkMgr, outIds = _a.outIds, outIdsAuto = _a.outIdsAuto; var base = (d === null || d === void 0 ? void 0 : d["Active"]) === false || (d === null || d === void 0 ? void 0 : d["MD_status"]) == "Inactive" ? "is-inactive " : ""; if (isLinked(d))
    return base + "is-linked"; if (outIdsAuto.includes(d["@rid"]))
    return base + "is-linked-auto"; if (outIds.includes(d["@rid"]))
    return base + "is-selected"; return base; };
var getLinkFromOut = function (outId) { return __awaiter(void 0, void 0, void 0, function () { var _a, selectedLink, selectedSource, link; return __generator(this, function (_b) {
    switch (_b.label) {
        case 0:
            if (!outId) return [3 /*break*/, 2];
            _a = store.getState().linkMgr, selectedLink = _a.selectedLink, selectedSource = _a.selectedSource;
            if (!selectedLink) return [3 /*break*/, 2];
            return [4 /*yield*/, Client.searchVertex(selectedLink.name, { in: selectedSource["@rid"], out: outId })];
        case 1:
            link = _b.sent();
            if (link && link.data.results && link.data.results.length)
                return [2 /*return*/, link.data.results[0]['Referential']];
            _b.label = 2;
        case 2: return [2 /*return*/, undefined];
    }
}); }); };
var getLinks = function (link) { return __awaiter(void 0, void 0, void 0, function () {
    var _link$name, _link$name2, t, res, _res, links;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                t = StartMeasureTime();
                if (!link) {
                    indexerLinks = {};
                    indexerLinksOneWay = {};
                    indexerAllLinks = {};
                }
                else {
                    delete indexerLinks[link.name];
                    delete indexerLinksOneWay[link.name];
                    delete indexerAllLinks[link.name];
                    Object.keys(indexerLinksBySource).forEach(function (k) { delete indexerLinksBySource[k][link.name]; });
                }
                return [4 /*yield*/, Client.searchVertex((_link$name = link === null || link === void 0 ? void 0 : link.name) !== null && _link$name !== void 0 ? _link$name : ReferentialHasViews.name, { 'in.@class': sources })];
            case 1:
                res = _a.sent();
                if (res.data.results)
                    res.data.results.forEach(function (link) { if (link) {
                        var classKey = link["@class"];
                        if (classKey) {
                            if (!indexerAllLinksBySource[link.in])
                                indexerAllLinksBySource[link.in] = {};
                            if (!indexerAllLinksBySource[link.in][classKey])
                                indexerAllLinksBySource[link.in][classKey] = [];
                            if (!indexerAllLinks[classKey])
                                indexerAllLinks[classKey] = [];
                            indexerAllLinks[classKey].push(link["@rid"]);
                            indexerAllLinksBySource[link.in][classKey].push(link["@rid"]);
                        }
                    } if (link && link.Referential && link.Referential !== "") {
                        var classKey = link["@class"];
                        if (classKey) {
                            if (!indexerLinksBySource[link.in])
                                indexerLinksBySource[link.in] = {};
                            if (!indexerLinksBySource[link.in][classKey]) {
                                indexerLinksBySource[link.in][classKey] = { Referentials: [], Views: [] };
                            }
                            if (!indexerLinks[classKey])
                                indexerLinks[classKey] = {};
                            if (!indexerLinksOneWay[classKey])
                                indexerLinksOneWay[classKey] = {};
                            if (!indexerLinksBySource[link.in][classKey]["Referentials"].includes(link.Referential))
                                indexerLinksBySource[link.in][classKey]["Referentials"].push(link.Referential);
                            if (!indexerLinksBySource[link.in][classKey]["Views"].includes(link.out))
                                indexerLinksBySource[link.in][classKey]["Views"].push(link.out);
                            indexerLinks[classKey][link.out] = link.Referential;
                            indexerLinks[classKey][link.Referential] = link.out;
                            indexerLinksOneWay[classKey][link.out] = link.Referential;
                        }
                    } }); // First, get dimensions and links
                return [4 /*yield*/, Client.getDimensionLinkTypes(ReferentialHasViews.name)];
            case 2:
                _res = _a.sent();
                links = _res.data.results || [];
                PrintMesureTime(t, "getLinks ".concat((_link$name2 = link === null || link === void 0 ? void 0 : link.name) !== null && _link$name2 !== void 0 ? _link$name2 : ""));
                return [2 /*return*/, links];
        }
    });
}); };
export var updateLinks = function (link) { return __awaiter(void 0, void 0, void 0, function () { var links; return __generator(this, function (_a) {
    switch (_a.label) {
        case 0:
            store.dispatch(loadingLinks(true));
            return [4 /*yield*/, getLinks(link)];
        case 1:
            links = _a.sent();
            store.dispatch(setState({ links: links }));
            store.dispatch(loadingLinks(false));
            if (!link)
                getData();
            return [2 /*return*/];
    }
}); }); };
export var updateVertex = function (links) { return __awaiter(void 0, void 0, void 0, function () {
    var selectedLink;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                selectedLink = store.getState().linkMgr.selectedLink;
                return [4 /*yield*/, Client.updateVertex(selectedLink.name, links)];
            case 1:
                _a.sent();
                return [4 /*yield*/, updateLinks(selectedLink)];
            case 2:
                _a.sent(); // store.dispatch(setState({ outIds: [], outIdsAuto: [], inId: undefined, topModalitiesViews: undefined }))
                return [2 /*return*/];
        }
    });
}); };
export var divModalityUnlinkHandler = function (_rid) { var _a = store.getState().linkMgr, selectedLink = _a.selectedLink, selectedSource = _a.selectedSource; Client.searchVertex(selectedLink.name, { in: selectedSource["@rid"], out: _rid }).then(function (res) { var links = res.data.results; links.forEach(function (link) { link.Referential = null; }); if (window.confirm("Le lien ".concat(_rid, " va etre supprim\xE9 : ").concat(JSON.stringify(links)))) {
    updateVertex(links);
} }); };
export var ClickModalityHandler = function (_event) {
    return __awaiter(this, arguments, void 0, function () {
        var updatefilters, _a, modalitiesViews, outIds, outIdsAuto, inId, res, view, newOutIds_1, indexOf, newOutIdsAuto, indexOfAuto, newRid, outIds_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    updatefilters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
                    _a = store.getState().linkMgr, modalitiesViews = _a.modalitiesViews, outIds = _a.outIds, outIdsAuto = _a.outIdsAuto, inId = _a.inId;
                    res = false;
                    view = _event.dataItem;
                    if (!!isLinked(view, "Views")) return [3 /*break*/, 1];
                    newOutIds_1 = __spreadArray([], outIds, true);
                    indexOf = newOutIds_1.indexOf(_event.dataItem["@rid"]);
                    if (indexOf >= 0) {
                        newOutIds_1.splice(indexOf, 1);
                    }
                    else {
                        newOutIds_1.push(_event.dataItem["@rid"]);
                    }
                    newOutIdsAuto = __spreadArray([], outIdsAuto, true);
                    indexOfAuto = newOutIdsAuto.indexOf(_event.dataItem["@rid"]);
                    if (indexOfAuto >= 0) {
                        newOutIdsAuto.splice(indexOfAuto, 1);
                    }
                    store.dispatch(setState({ outIds: newOutIds_1, outIdsAuto: newOutIdsAuto, topModalitiesViews: modalitiesViews === null || modalitiesViews === void 0 ? void 0 : modalitiesViews.filter(function (m) { return newOutIds_1.includes(m["@rid"]); }) }));
                    res = true;
                    return [3 /*break*/, 4];
                case 1:
                    if (inId)
                        if (!window.confirm("Les modifications non enregistrées vont être perdues")) {
                            return [2 /*return*/];
                        }
                    return [4 /*yield*/, getLinkFromOut(_event.dataItem["@rid"])];
                case 2:
                    newRid = _b.sent();
                    return [4 /*yield*/, getLinkFromIn(newRid)];
                case 3:
                    outIds_2 = _b.sent();
                    store.dispatch(setState({ inId: newRid, outIds: outIds_2, topModalitiesViews: modalitiesViews === null || modalitiesViews === void 0 ? void 0 : modalitiesViews.filter(function (m) { return outIds_2.includes(m["@rid"]); }) }));
                    _b.label = 4;
                case 4:
                    if (updatefilters)
                        UpdateFilteredModalityViews();
                    return [2 /*return*/, res];
            }
        });
    });
};
export function updateReferential(selectedDimension) {
    return __awaiter(this, void 0, void 0, function () {
        var selectedProptype, properties, params, moda, modalities;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    store.dispatch(loadingGridRef(true));
                    selectedProptype = store.getState().linkMgr.selectedProptype;
                    properties = ["*", "out('lnk_Hierarchy') as parentRid"];
                    if ([ref_Supports.name, ref_Property.name].includes(selectedDimension)) {
                        properties.push("first(Medias.Name) as Media");
                    }
                    if (selectedDimension == ref_Supports.name) { //properties.push("out(\"lnk_AdvertisingCompanySupport\").Name as Suppliers");
                        properties.push("out(\"lnk_AdvertisingCompanySupport\") as Suppliers");
                    }
                    params = { Active: [true, false], properties: properties };
                    if (selectedDimension == ref_Companies.name)
                        params = __assign(__assign({}, params), { "@class": [ref_Agencies.name, ref_AgencyGroups.name] });
                    return [4 /*yield*/, Client.searchVertex(selectedDimension, params)];
                case 1:
                    moda = _a.sent();
                    modalities = moda.data.results;
                    modalities.forEach(function (m) {
                        var mAny = m;
                        if (!m.Name && mAny.name)
                            m.Name = mAny.name;
                    });
                    if (modalities) {
                        if (selectedDimension == ref_Property.name) {
                            modalities = modalities.filter(function (m) { var c = m; return c.PropertyType.includes(selectedProptype === null || selectedProptype === void 0 ? void 0 : selectedProptype["@rid"]); });
                        }
                        modalities = __spreadArray([], modalities, true).sort(function (a, b) { return a.Name.localeCompare(b.Name); });
                        store.dispatch(loadingGridRef(false));
                        store.dispatch(setState({ modalities: modalities, outIds: [], inId: undefined, topModalitiesViews: undefined, loading: false }));
                    }
                    return [2 /*return*/];
            }
        });
    });
}
export function selectLink(selectedLink) {
    return __awaiter(this, void 0, void 0, function () {
        var metadata, _moda$data, _moda$data$results, _a, selectedSource, modalities, inId, timeTotal, timeSearchVertex, moda, views, modalitiesViews, timeFilter, filteredModalitiesViews;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    metadata = getProp(selectedLink, "out");
                    if (!metadata) return [3 /*break*/, 2];
                    store.dispatch(loadingGridView(true));
                    _a = store.getState().linkMgr, selectedSource = _a.selectedSource, modalities = _a.modalities, inId = _a.inId;
                    timeTotal = StartMeasureTime();
                    timeSearchVertex = StartMeasureTime();
                    return [4 /*yield*/, Client.searchVertex(selectedLink.name, { in: selectedSource["@rid"], properties: ["expand(out)"] })];
                case 1:
                    moda = _b.sent();
                    PrintMesureTime(timeSearchVertex, "SearchVertex ".concat(selectedLink.name, " (").concat(moda === null || moda === void 0 ? void 0 : (_moda$data = moda.data) === null || _moda$data === void 0 ? void 0 : (_moda$data$results = _moda$data.results) === null || _moda$data$results === void 0 ? void 0 : _moda$data$results.length, ")"));
                    views = moda.data.results;
                    modalitiesViews = getModalities(views);
                    modalitiesViews.sort(function (a, b) { if (!a.Label)
                        return -1; return a.Label.localeCompare(b.Label); });
                    store.dispatch(loadingGridView(false));
                    timeFilter = StartMeasureTime();
                    filteredModalitiesViews = GetFilteredModalitiesViews(modalitiesViews);
                    PrintMesureTime(timeFilter, "GetFilteredModalitiesViews");
                    PrintMesureTime(timeTotal, "SelectLink");
                    store.dispatch(setState({ modalitiesViews: modalitiesViews, outIds: [], inId: undefined, topModalitiesViews: undefined, filteredModalitiesViews: filteredModalitiesViews, loading: false }));
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
export var getData = function () { return __awaiter(void 0, void 0, void 0, function () { var _a, selectedDimension, selectedLink; return __generator(this, function (_b) {
    switch (_b.label) {
        case 0:
            _a = store.getState().linkMgr, selectedDimension = _a.selectedDimension, selectedLink = _a.selectedLink;
            ConsoleDebug("linkmgr getData");
            if (!selectedDimension) return [3 /*break*/, 4];
            return [4 /*yield*/, updateReferential(selectedDimension)];
        case 1:
            _b.sent();
            if (!selectedLink) return [3 /*break*/, 3];
            return [4 /*yield*/, selectLink(selectedLink)];
        case 2:
            _b.sent();
            return [3 /*break*/, 4];
        case 3:
            store.dispatch(setState({ modalitiesViews: [], outIds: [], inId: undefined, topModalitiesViews: undefined, filteredModalitiesViews: [], loading: false }));
            _b.label = 4;
        case 4: return [2 /*return*/];
    }
}); }); };
