var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { DateNoZone } from "tools-lib";
import { TabManagerEvent, eventTabManagerChanged } from "../components/tabPanel/TabManager.bin";
import { clone, duplicateObject, extractSub, hasOwnProperty, propertyOf } from "hub-lib/tools.bin";
import { AdvertiserHierarchyFilter, Filter } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import { ref_FilterConfigurations } from "hub-lib/dto/client/ref_FilterConfigurations.bin";
import { ref_ProjectConfigurations } from "hub-lib/dto/client/ref_ProjectConfigurations.bin";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ref_ExportConfigurations } from "hub-lib/dto/client/ref_ExportConfigurations.bin";
import { ref_TableConfigurations } from "hub-lib/dto/client/ref_TableConfigurations.bin";
import { src_MM } from "hub-lib/models/orientdb/src_MM.bin";
import { Client } from "hub-lib/client/client.bin";
import { eFunctions, eRights, RightManager } from "hub-lib/models/types/rights.bin";
import { ref_SchedulerConfigurations } from "hub-lib/dto/client/ref_SchedulerConfigurations.bin";
import { src_TSM } from "hub-lib/models/orientdb/src_TSM.bin";
import { ref_DashboardConfigurations } from "hub-lib/dto/client/ref_DashboardConfigurations.bin";
TabManagerEvent.addListener(eventTabManagerChanged, SetTabManager);
var keyLayout = 'dashboard-layout';
var keyDicoTabMgr = 'dictionary-tabmanager-1';
var keyExport = 'export-config-params-1';
var UserKey = function () { var _JSON$parse; return "User-" + ((_JSON$parse = JSON.parse(localStorage.getItem('user'))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse["@rid"]); };
export function GetUser() { var _JSON$parse2; return (_JSON$parse2 = JSON.parse(localStorage.getItem('user'))) !== null && _JSON$parse2 !== void 0 ? _JSON$parse2 : {}; }
export function IsMMUser() { var _GetUser, _GetUser$sources; return (_GetUser = GetUser()) === null || _GetUser === void 0 ? void 0 : (_GetUser$sources = _GetUser.sources) === null || _GetUser$sources === void 0 ? void 0 : _GetUser$sources.some(function (s) { return s["@class"] == src_MM.name; }); }
export function IsSellsyUser() { var _GetUser2, _GetUser2$customer; return ((_GetUser2 = GetUser()) === null || _GetUser2 === void 0 ? void 0 : (_GetUser2$customer = _GetUser2.customer) === null || _GetUser2$customer === void 0 ? void 0 : _GetUser2$customer.Billing) === "AdwOne"; }
export function GetSrcMMUser() { var _GetUser3, _GetUser3$sources; return (_GetUser3 = GetUser()) === null || _GetUser3 === void 0 ? void 0 : (_GetUser3$sources = _GetUser3.sources) === null || _GetUser3$sources === void 0 ? void 0 : _GetUser3$sources.find(function (s) { return s["@class"] == src_MM.name; }); }
export function GetSrcTSMUser() { var _GetUser4, _GetUser4$sources; return (_GetUser4 = GetUser()) === null || _GetUser4 === void 0 ? void 0 : (_GetUser4$sources = _GetUser4.sources) === null || _GetUser4$sources === void 0 ? void 0 : _GetUser4$sources.find(function (s) { return s["@class"] == src_TSM.name; }); }
var Storage = /** @class */ (function () {
    function Storage() {
    }
    Storage.vertex = function () { throw new Error("modeleFilterKey not implemented"); };
    Storage.modeleFilterKey = function () { throw new Error("modeleFilterKey not implemented"); };
    Storage.get = function () { var repair = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true; try {
        var store = localStorage.getItem(this.modeleFilterKey());
        if (store) {
            var parsed = this.parse(store);
            if (repair) {
                return this.repair(parsed, this.vertex());
            }
            return parsed;
        }
    }
    catch (error) {
        console.log(error);
    } return null; };
    Storage.repair = function (store, Vertex) { return __assign(__assign({}, new Vertex()), store); };
    Storage.set = function (data, prefix) { localStorage.setItem(prefix !== null && prefix !== void 0 ? prefix : "" + this.modeleFilterKey(), JSON.stringify(data)); };
    Storage.clear = function () { console.log("clearing ".concat(this.modeleFilterKey(), " localstorage")); localStorage.removeItem(this.modeleFilterKey()); };
    Storage.parse = function (store) {
        try {
            return JSON.parse(store);
        }
        catch (error) { // add error
            console.log(error);
        }
    };
    Storage.getLocalStorageValue = function (_key) { var store = localStorage.getItem(UserKey() + "-" + _key); return store ? this.parse(store) : undefined; };
    Storage.setLocalStorageValue = function (_key, data) { var store = localStorage.setItem(UserKey() + "-" + _key, JSON.stringify(data)); return store; };
    Storage.isValid = function (store) { var vertexName = this.vertex().name; var metadata = MetadataStorage.get(); if (!metadata[vertexName]) {
        return true;
    } var metaerror = metadata[vertexName].filter(function (m) { return m.mandatory && !hasOwnProperty(store, m.name); }); if (metaerror.length) {
        console.log("⚠ localstorage [isValid] properties missing", metaerror);
    } return metaerror.length === 0; };
    return Storage;
}());
var timeout = 600000 * 6 * 24;
var intervalTrads = undefined;
export var defaultFilterConfig;
export var defaultProjectConfig;
export var defaultExportConfig;
export var defaultTableConfig;
export var defaultDashboardConfig;
export var defaultSchedulerConfig;
var MetadataStorage = /** @class */ (function (_super) {
    __extends(MetadataStorage, _super);
    function MetadataStorage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MetadataStorage.initialize = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, metadatas, _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, defaultConf, defaultConfProject, defaultConfExport, defaultConfTable, defaultConfDashboard, defaultConfScheduler;
            var _o, _p, _q, _r, _s, _t;
            var _this = this;
            return __generator(this, function (_u) {
                switch (_u.label) {
                    case 0:
                        data = this.get(true);
                        if (!!Object.keys(data).length) return [3 /*break*/, 7];
                        metadatas = {};
                        _a = metadatas;
                        _b = ref_FilterConfigurations.name;
                        return [4 /*yield*/, Client.getMetadata(ref_FilterConfigurations.name)];
                    case 1:
                        _a[_b] = (_u.sent()).data.results;
                        _c = metadatas;
                        _d = ref_TableConfigurations.name;
                        return [4 /*yield*/, Client.getMetadata(ref_TableConfigurations.name)];
                    case 2:
                        _c[_d] = (_u.sent()).data.results;
                        _e = metadatas;
                        _f = ref_DashboardConfigurations.name;
                        return [4 /*yield*/, Client.getMetadata(ref_DashboardConfigurations.name)];
                    case 3:
                        _e[_f] = (_u.sent()).data.results;
                        _g = metadatas;
                        _h = ref_ProjectConfigurations.name;
                        return [4 /*yield*/, Client.getMetadata(ref_ProjectConfigurations.name)];
                    case 4:
                        _g[_h] = (_u.sent()).data.results;
                        _j = metadatas;
                        _k = ref_ExportConfigurations.name;
                        return [4 /*yield*/, Client.getMetadata(ref_ExportConfigurations.name)];
                    case 5:
                        _j[_k] = (_u.sent()).data.results;
                        _l = metadatas;
                        _m = ref_SchedulerConfigurations.name;
                        return [4 /*yield*/, Client.getMetadata(ref_SchedulerConfigurations.name)];
                    case 6:
                        _l[_m] = (_u.sent()).data.results;
                        this.set(metadatas);
                        clearInterval(intervalTrads);
                        intervalTrads = setInterval(function () { return _this.initialize(); }, timeout);
                        _u.label = 7;
                    case 7:
                        if (!!defaultFilterConfig) return [3 /*break*/, 9];
                        return [4 /*yield*/, Client.searchVertexTyped(ref_FilterConfigurations, (_o = {}, _o[propertyOf('Default')] = true, _o[propertyOf('Table')] = ref_Messages.name, _o))];
                    case 8:
                        defaultConf = (_u.sent())[0];
                        defaultFilterConfig = defaultConf;
                        _u.label = 9;
                    case 9:
                        if (!(!defaultProjectConfig && RightManager.hasRight(eFunctions.ref_ProjectConfigurations, eRights.read))) return [3 /*break*/, 11];
                        return [4 /*yield*/, Client.searchVertexTyped(ref_ProjectConfigurations, (_p = {}, _p[propertyOf('Default')] = true, _p[propertyOf('Table')] = ref_Messages.name, _p))];
                    case 10:
                        defaultConfProject = (_u.sent())[0];
                        defaultProjectConfig = defaultConfProject;
                        _u.label = 11;
                    case 11:
                        if (!!defaultExportConfig) return [3 /*break*/, 13];
                        return [4 /*yield*/, Client.searchVertexTyped(ref_ExportConfigurations, (_q = {}, _q[propertyOf('Default')] = true, _q[propertyOf('Table')] = ref_Messages.name, _q))];
                    case 12:
                        defaultConfExport = (_u.sent())[0];
                        defaultExportConfig = defaultConfExport;
                        _u.label = 13;
                    case 13:
                        if (!!defaultTableConfig) return [3 /*break*/, 15];
                        return [4 /*yield*/, Client.searchVertexTyped(ref_TableConfigurations, (_r = {}, _r[propertyOf('Default')] = true, _r[propertyOf('Table')] = ref_Messages.name, _r))];
                    case 14:
                        defaultConfTable = (_u.sent())[0];
                        defaultTableConfig = defaultConfTable;
                        _u.label = 15;
                    case 15:
                        if (!!defaultDashboardConfig) return [3 /*break*/, 17];
                        return [4 /*yield*/, Client.searchVertexTyped(ref_DashboardConfigurations, (_s = {}, _s[propertyOf('Default')] = true, _s[propertyOf('Table')] = ref_Messages.name, _s))];
                    case 16:
                        defaultConfDashboard = (_u.sent())[0];
                        defaultDashboardConfig = defaultConfDashboard;
                        _u.label = 17;
                    case 17:
                        if (!!defaultSchedulerConfig) return [3 /*break*/, 19];
                        return [4 /*yield*/, Client.searchVertexTyped(ref_SchedulerConfigurations, (_t = {}, _t[propertyOf('Default')] = true, _t[propertyOf('Table')] = ref_Messages.name, _t))];
                    case 18:
                        defaultConfScheduler = (_u.sent())[0];
                        defaultSchedulerConfig = defaultConfScheduler;
                        _u.label = 19;
                    case 19: return [2 /*return*/];
                }
            });
        });
    };
    MetadataStorage.get = function () { var checkTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false; var storage = _super.get.call(this, false); if (storage) {
        if (checkTime) {
            var expiration = new Date(storage.expires);
            if (new Date().getTime() < expiration.getTime())
                return storage.metadatas;
            else
                return {};
        }
        else {
            return storage.metadatas;
        }
    } return {}; };
    MetadataStorage.set = function (data) { var expires = new Date(); expires.setMilliseconds(expires.getMilliseconds() + timeout); var storage = { expires: expires === null || expires === void 0 ? void 0 : expires.toString(), metadatas: data }; _super.set.call(this, storage); };
    return MetadataStorage;
}(Storage));
export { MetadataStorage };
MetadataStorage.modeleFilterKey = function () { return "all-metadatas"; };
var FilterStorage = /** @class */ (function (_super) {
    __extends(FilterStorage, _super);
    function FilterStorage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FilterStorage.repairFilters = function (filterConf) {
        if (filterConf.Filters) {
            var _ClearEmptyValues;
            filterConf.Filters = (_ClearEmptyValues = ClearEmptyValues(filterConf.Filters)) !== null && _ClearEmptyValues !== void 0 ? _ClearEmptyValues : {};
            filterConf.Filters = extractSub(filterConf.Filters, Object.keys(new AdvertiserHierarchyFilter())); // if (filterConf.Filters.Start) filterConf.Filters.Start = DateNoZone(filterConf.Filters.Start);
            // else filterConf.Filters.Start = new Filter().Start;
            // if (filterConf.Filters.End) filterConf.Filters.End = DateNoZone(filterConf.Filters.End);
            // else filterConf.Filters.End = new Filter().End;
            // if (!filterConf.Filters.Source) filterConf.Filters.Source = new Filter().Source;
        }
    };
    FilterStorage.get = function () { var parsed = _super.get.call(this); if (parsed) {
        FilterStorage.repairFilters(parsed);
        if (this.isValid(parsed)) {
            return parsed;
        }
        this.clear();
    } return clone(defaultFilterConfig); };
    FilterStorage.getAdvancedFilters = function () { var store = localStorage.getItem(UserKey() + "-" + 'Advanced-filters'); if (store) {
        var parsed = this.parse(store);
        if (parsed.Start)
            parsed.Start = DateNoZone(parsed.Start);
        else
            parsed.Start = new Filter().Start;
        if (parsed.End)
            parsed.End = DateNoZone(parsed.End);
        else
            parsed.End = new Filter().End;
        if (!parsed.Source)
            parsed.Source = new Filter().Source;
        return parsed;
    } return clone(new Filter()); };
    FilterStorage.setAdvancedFilters = function (filters) { this.setLocalStorageValue('Advanced-filters', filters !== null && filters !== void 0 ? filters : {}); };
    FilterStorage.set = function (filter) { if (filter.Filters)
        filter.Filters = ClearEmptyValues(filter.Filters); FilterStorage.setAdvancedFilters(filter === null || filter === void 0 ? void 0 : filter.Filters); FilterStorage.repairFilters(filter); this.setLocalStorageValue(ref_FilterConfigurations.name, filter); };
    FilterStorage.GetLocalStorageFiltersObj = function () { return this.getAdvancedFilters(); };
    return FilterStorage;
}(Storage));
export { FilterStorage };
FilterStorage.vertex = function () { return ref_FilterConfigurations; };
FilterStorage.modeleFilterKey = function () { return UserKey() + "-" + ref_FilterConfigurations.name; };
var TableStorage = /** @class */ (function (_super) {
    __extends(TableStorage, _super);
    function TableStorage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TableStorage.set = function (data) { _super.set.call(this, data); };
    TableStorage.getAll = function () { var parsed = _super.get.call(this); return parsed !== null && parsed !== void 0 ? parsed : {}; };
    return TableStorage;
}(Storage));
export { TableStorage };
TableStorage.vertex = function () { return ref_TableConfigurations; };
TableStorage.modeleFilterKey = function () { return "".concat(UserKey(), "-").concat(ref_TableConfigurations.name, "-table-storage"); };
var ProjectStorage = /** @class */ (function (_super) {
    __extends(ProjectStorage, _super);
    function ProjectStorage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectStorage.get = function () { var parsed = _super.get.call(this); if (parsed) {
        if (this.isValid(parsed))
            return parsed;
        this.clear();
    } return clone(defaultProjectConfig); };
    ProjectStorage.set = function (data) { this.setLocalStorageValue(ref_ProjectConfigurations.name, data); };
    return ProjectStorage;
}(Storage));
export { ProjectStorage };
ProjectStorage.vertex = function () { return ref_ProjectConfigurations; };
ProjectStorage.modeleFilterKey = function () { return UserKey() + "-" + ref_ProjectConfigurations.name; };
var ExportStorage = /** @class */ (function (_super) {
    __extends(ExportStorage, _super);
    function ExportStorage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExportStorage.get = function () { var parsed = _super.get.call(this); if (parsed) {
        if (this.isValid(parsed))
            return parsed;
        this.clear();
    } return clone(defaultExportConfig); };
    ExportStorage.set = function (exportConfig) { this.setLocalStorageValue(ref_ExportConfigurations.name, exportConfig); };
    return ExportStorage;
}(Storage));
export { ExportStorage };
ExportStorage.vertex = function () { return ref_ExportConfigurations; };
ExportStorage.modeleFilterKey = function () { return UserKey() + "-" + ref_ExportConfigurations.name; };
var DashboardStorage = /** @class */ (function (_super) {
    __extends(DashboardStorage, _super);
    function DashboardStorage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DashboardStorage.get = function () { var parsed = _super.get.call(this); if (parsed) {
        if (this.isValid(parsed))
            return parsed;
        this.clear();
    } return clone(defaultDashboardConfig); };
    DashboardStorage.set = function (dashboardConfig) { this.setLocalStorageValue(ref_DashboardConfigurations.name, dashboardConfig); };
    return DashboardStorage;
}(Storage)); // export class SchedulerStorage extends Storage {
export { DashboardStorage };
//     protected static vertex = () => ref_SchedulerConfigurations;
//     protected static modeleFilterKey = () => UserKey() + "-" + ref_SchedulerConfigurations.name;
//     static get(): ref_SchedulerConfigurations {
//         const parsed = super.get() as ref_SchedulerConfigurations;
//         if (parsed) {
//             if (this.isValid(parsed))
//                 return parsed;
//             this.clear();
//         }
//         return clone(defaultSchedulerConfig);
//     }
//     static set(config: ref_SchedulerConfigurations) {
//         this.setLocalStorageValue(ref_SchedulerConfigurations.name, config);
//     }
// }
// get storage class from string
DashboardStorage.vertex = function () { return ref_DashboardConfigurations; };
DashboardStorage.modeleFilterKey = function () { return UserKey() + "-" + ref_DashboardConfigurations.name; };
export function getStorageClass(name) {
    switch (name) {
        case ref_FilterConfigurations.name: return FilterStorage; // case ref_TableConfigurations.name:
        //     return TableStorage;
        case ref_DashboardConfigurations.name: return DashboardStorage;
        case ref_ProjectConfigurations.name: return ProjectStorage;
        case ref_ExportConfigurations.name: return ExportStorage; // case ref_SchedulerConfigurations.name:
        //     return SchedulerStorage;
        default: return Storage;
    }
}
function GetLocalStorageChartFilters(chartKey) { return localStorage.getItem(UserKey() + "-" + chartKey); }
export function GetLocalStorageFiltersChartObj(chartKey) { var store = GetLocalStorageChartFilters(chartKey); if (store) {
    var res = JSON.parse(store);
    if (res.Start)
        res.Start = DateNoZone(res.Start);
    if (res.End)
        res.End = DateNoZone(res.End);
    if (res.Source)
        delete res.Source;
    return res;
} return undefined; }
export function SetLocalStorageChartFilters(chartKey, params) { localStorage.setItem(UserKey() + "-" + chartKey, JSON.stringify(params)); }
export function RemoveLocalStorageChartFilters(chartKey) { localStorage.removeItem(chartKey); }
export function IsPerformanceDebugMode() { return localStorage.getItem("perfMode") === "1"; }
var timeMeasureId = 0;
var timeMeasures = {};
export function StartMeasureTime() { if (!IsPerformanceDebugMode())
    return; var id = ++timeMeasureId; timeMeasures[id] = new Date().getTime(); return id; }
export function PrintMesureTime(idMeasure, msg) { if (!IsPerformanceDebugMode())
    return; var elapsed = new Date().getTime() - timeMeasures[idMeasure]; delete timeMeasures[idMeasure]; console.log("[DEBUG] ".concat(msg !== null && msg !== void 0 ? msg : "Elapsed: ", " ").concat(elapsed, "ms")); }
export function IsDebugMode() { return localStorage.getItem("devMode") === "1"; }
export function ConsoleDebug(message) { for (var _len = arguments.length, optionalParams = new Array(_len > 1 ? _len - 1 : 0), _key2 = 1; _key2 < _len; _key2++) {
    optionalParams[_key2 - 1] = arguments[_key2];
} if (IsDebugMode())
    console.log.apply(console, __spreadArray(["[DEBUG] ".concat(message)], optionalParams, false)); }
export function SetLocalStorageExportFormatedConfiguration(params) { if (IsDebugMode()) {
    console.log("SetLocalStorageExportFormatedConfiguration", params);
    console.log("SetLocalStorageExportFormatedConfiguration2", duplicateObject(params));
} localStorage.setItem(keyExport, JSON.stringify(duplicateObject(params))); }
export function GetLocalStorageExportFormatedConfiguration() { var res = localStorage.getItem(keyExport); res = res && JSON.parse(res); if (IsDebugMode())
    console.log("GetLocalStorageExportFormatedConfiguration", res); return res; }
export function GetLocalStorageNotifications(limit) { var notifStorage = localStorage.getItem('notifications'); if (!notifStorage)
    return []; return JSON.parse(notifStorage).reverse().slice(0, limit); }
function ClearEmptyValues(state) { var newParams = __assign({}, state); for (var _i = 0, _a = Object.entries(state); _i < _a.length; _i++) {
    var _b = _a[_i], key = _b[0], value = _b[1];
    if (Array.isArray(value) && value.length === 0) {
        delete newParams[key];
    }
} return newParams; } /**
 * Configuration tableau
 */ /**
* Configuration tableau
* @param key
*/ // export function GetConfigurationTab(keyStr: string) {
//     return localStorage.getItem(keyConfTab + keyStr);
// }
/**
 * Configuration tableau
 * @param key
 * @param value
 */ // export function SetConfigurationTab(keyStr: string, value: string) {
//     return localStorage.setItem(keyConfTab + keyStr, value);
// }
/**
 * DashBoard layout
 */ export function GetLayout() { var store = localStorage.getItem(keyLayout); if (store)
    return JSON.parse(store); return []; }
export function SetLayout(value) { return localStorage.setItem(keyLayout, JSON.stringify(value)); } /**
* TAB manager
*/
export function SetTabManager(tabManager) { var store = localStorage.getItem(keyDicoTabMgr); var dico = store ? JSON.parse(store) : {}; dico[tabManager.id] = tabManager; localStorage.setItem(keyDicoTabMgr, JSON.stringify(dico)); }
