var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ClassProperty } from "./VertexProperty.bin";
export var eCode;
(function (eCode) {
    eCode["Unknown"] = "Unknown";
    eCode["System"] = "System";
    eCode["Validation"] = "Validation";
    eCode["Restricted"] = "Restricted";
    eCode["Create"] = "Create";
    eCode["Update"] = "Update";
    eCode["Delete"] = "Delete";
    eCode["Persist"] = "Persist";
})(eCode || (eCode = {}));
export var eBusinessCode;
(function (eBusinessCode) {
    eBusinessCode["UnknownError"] = "UnknownError";
    eBusinessCode["MissingProperty"] = "MissingProperty";
    eBusinessCode["AlreadyExist"] = "AlreadyExist";
    eBusinessCode["RecordNotFound"] = "RecordNotFound";
    eBusinessCode["Unautorized"] = "Unautorized";
    eBusinessCode["BadProperty"] = "BadProperty";
})(eBusinessCode || (eBusinessCode = {}));
var HubResponse = /** @class */ (function () {
    function HubResponse() {
    }
    return HubResponse;
}());
export { HubResponse };
export function GetSuffixe(type) {
    var _a;
    var _b;
    return (_b = (_a = {},
        _a[eColumnType.DiscountFO] = " FO",
        _a[eColumnType.DiscountFOS] = " FOS",
        _a[eColumnType.DiscountFOValue] = " FO",
        _a[eColumnType.DiscountFOSValue] = " FOS",
        _a[eColumnType.DiscountFOValueBound] = " FO",
        _a)[type]) !== null && _b !== void 0 ? _b : "";
}
export function IsBound(type) {
    return [eColumnType.DiscountValueBound, eColumnType.DiscountFOValueBound].includes(type);
}
export function IsDiscount(type) {
    return ([
        eColumnType.Discount,
        eColumnType.DiscountFO,
        eColumnType.DiscountFOS,
        eColumnType.DiscountValue,
        eColumnType.DiscountFOValue,
        eColumnType.DiscountFOSValue,
        eColumnType.DiscountValueBound,
        eColumnType.DiscountFOValueBound
    ].includes(type));
}
export function IsDiscountRateColumn(type) {
    return ([
        eColumnType.Discount,
        eColumnType.DiscountFO,
        eColumnType.DiscountFOS
    ].includes(type));
}
export function IsDiscountCO(type) {
    return ([
        eColumnType.Discount,
        eColumnType.DiscountValue,
        eColumnType.DiscountValueBound
    ].includes(type));
}
export function IsDiscountFO(type) {
    return ([
        eColumnType.DiscountFO,
        eColumnType.DiscountFOValue,
        eColumnType.DiscountFOValueBound
    ].includes(type));
}
export function IsDiscountFOS(type) {
    return ([
        eColumnType.DiscountFOS,
        eColumnType.DiscountFOSValue
    ].includes(type));
}
export var eColumnType;
(function (eColumnType) {
    eColumnType["KPI"] = "KPI";
    eColumnType["Barter"] = "Barter";
    eColumnType["Property"] = "Property";
    eColumnType["PriceReturned"] = "PriceReturned";
    eColumnType["PriceBound"] = "PriceBound";
    eColumnType["Discount"] = "Discount";
    eColumnType["DiscountFO"] = "DiscountFO";
    eColumnType["DiscountFOS"] = "DiscountFOS";
    eColumnType["DiscountValue"] = "DiscountValue";
    eColumnType["DiscountFOValue"] = "DiscountFOValue";
    eColumnType["DiscountFOSValue"] = "DiscountFOSValue";
    eColumnType["DiscountValueBound"] = "DiscountValueBound";
    eColumnType["DiscountFOValueBound"] = "DiscountFOValueBound";
})(eColumnType || (eColumnType = {}));
var res = /** @class */ (function (_super) {
    __extends(res, _super);
    function res() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return res;
}(HubResponse));
export { res };
var ValidationResponse = /** @class */ (function (_super) {
    __extends(ValidationResponse, _super);
    function ValidationResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ValidationResponse;
}(HubResponse));
export { ValidationResponse };
var OrientClassMeta = /** @class */ (function () {
    function OrientClassMeta() {
    }
    return OrientClassMeta;
}());
export { OrientClassMeta };
var MetaDataProperty = /** @class */ (function (_super) {
    __extends(MetaDataProperty, _super);
    /**
     *
     */
    function MetaDataProperty(base) {
        var _this = _super.call(this) || this;
        if (base) {
            Object.entries(base).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                _this[key] = value;
            });
        }
        return _this;
    }
    return MetaDataProperty;
}(ClassProperty));
export { MetaDataProperty };
