var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Client } from "../client/client.bin";
import { KPIsManagerCache } from "../models/KPIsManager.bin";
import { eIndicateurType } from "adwone-engine/index.bin";
import { toArray } from "../tools.bin";
import { getMonthName } from "tools-lib";
import moment from "moment";
import { eStatusType, ref_Messages } from "../dto/client/ref_Messages.bin";
import { Trad } from "trad-lib";
var defaultFilters = {
    Status: [
        eStatusType.Confirmed,
        eStatusType.None,
        eStatusType.Opted,
        eStatusType.Simulated,
        // eStatusType.Cancelled
    ],
    Source: ["ADWONE"]
};
var BasicAggregateManager = /** @class */ (function () {
    function BasicAggregateManager() {
    }
    BasicAggregateManager.prototype.getAggregate = function (objectType) {
        if (objectType === void 0) { objectType = ref_Messages; }
        return __awaiter(this, void 0, void 0, function () {
            var indicateur, lnks, lnk, arg;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.Indicateur) return [3 /*break*/, 1];
                        indicateur = this.Indicateur;
                        return [3 /*break*/, 3];
                    case 1:
                        if (!this.KPI) return [3 /*break*/, 3];
                        return [4 /*yield*/, KPIsManagerCache.GetInstance(objectType.name).GetLnkHasKPIs()];
                    case 2:
                        lnks = _a.sent();
                        lnk = lnks.find(function (l) { return l.KPI === _this.KPI; });
                        if (lnk)
                            indicateur = {
                                field: lnk === null || lnk === void 0 ? void 0 : lnk.Id,
                                valueType: lnk === null || lnk === void 0 ? void 0 : lnk.ValueType,
                                name: "",
                                type: eIndicateurType.kpi,
                                options: {
                                    rid: this.KPI
                                }
                            };
                        _a.label = 3;
                    case 3:
                        arg = {
                            hideDetailsRows: true,
                            type: "aggregate",
                            document: objectType.name,
                            dimensions: Array.isArray(this.dimension) ? this.dimension : [this.dimension],
                            columns: indicateur ? [indicateur] : [],
                            filter: __assign(__assign({}, defaultFilters), this.filters),
                        };
                        if (this.start || this.end) {
                            arg.Start = this.start;
                            arg.End = this.end;
                        }
                        return [2 /*return*/, Client.aggregate(arg)];
                }
            });
        });
    };
    BasicAggregateManager.prototype.get = function () {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, Promise, function () {
            var res, table, totalRow, isDate, rows, _i, _g, month, _h, _j, year, row, row, results;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0: return [4 /*yield*/, this.getAggregate()];
                    case 1:
                        res = _k.sent();
                        table = res.data.table;
                        totalRow = table.Rows[0];
                        isDate = toArray(this.dimension).find(function (d) { return d.field === "Start" || d.field === "End"; });
                        rows = {};
                        for (_i = 0, _g = totalRow === null || totalRow === void 0 ? void 0 : totalRow.Children; _i < _g.length; _i++) {
                            month = _g[_i];
                            if (month === null || month === void 0 ? void 0 : month.Children) {
                                for (_h = 0, _j = month === null || month === void 0 ? void 0 : month.Children; _h < _j.length; _h++) {
                                    year = _j[_h];
                                    row = {
                                        Label: isDate ? getMonthName(month === null || month === void 0 ? void 0 : month.Formated, true) : (_a = month === null || month === void 0 ? void 0 : month.Formated) !== null && _a !== void 0 ? _a : (Array.isArray(month === null || month === void 0 ? void 0 : month.Value) ? month.Value.join(",") : month.Value),
                                        Value: year === null || year === void 0 ? void 0 : year.Value,
                                        KPI: (_c = (_b = this.customKPI) === null || _b === void 0 ? void 0 : _b.call(this, year)) !== null && _c !== void 0 ? _c : year.ValuesTotal[0].Value,
                                        Origin: isDate ? moment(new Date(parseInt(year.Formated), parseInt(month.Formated) - 1)).utcOffset(0, true).format() : month.Formated + " " + year.Formated
                                    };
                                    rows[year === null || year === void 0 ? void 0 : year.Formated] = __spreadArray(__spreadArray([], (rows[year === null || year === void 0 ? void 0 : year.Formated] || []), true), [row], false);
                                }
                            }
                            else {
                                row = {
                                    Label: (month === null || month === void 0 ? void 0 : month.Value) === "undefined" ? Trad("none") : ((_d = month === null || month === void 0 ? void 0 : month.Formated) !== null && _d !== void 0 ? _d : (Array.isArray(month === null || month === void 0 ? void 0 : month.Value) ? month.Value.join(",") : month.Value)),
                                    Value: (month === null || month === void 0 ? void 0 : month.Value) === "undefined" ? null : month === null || month === void 0 ? void 0 : month.Value,
                                    KPI: (_f = (_e = this.customKPI) === null || _e === void 0 ? void 0 : _e.call(this, month)) !== null && _f !== void 0 ? _f : month.ValuesTotal[0].Value,
                                    Origin: month === null || month === void 0 ? void 0 : month.Formated
                                };
                                rows[totalRow.Formated] = __spreadArray(__spreadArray([], (rows[totalRow === null || totalRow === void 0 ? void 0 : totalRow.Formated] || []), true), [row], false);
                            }
                        }
                        results = Object.values(rows);
                        if (!isDate) {
                            results.forEach(function (r) { return r.sort(function (a, b) { return b.KPI - a.KPI; }); });
                        }
                        return [2 /*return*/, results];
                }
            });
        });
    };
    return BasicAggregateManager;
}());
export { BasicAggregateManager };
