var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { eIndicateurType } from "adwone-engine/index.bin";
import { eKPIType, KPIsManagerCache } from "./KPIsManager.bin";
export var eDiscountOptionValue;
(function (eDiscountOptionValue) {
    eDiscountOptionValue["Rate"] = "Rate";
    eDiscountOptionValue["Value"] = "Value";
})(eDiscountOptionValue || (eDiscountOptionValue = {}));
export var eDiscountOptionType;
(function (eDiscountOptionType) {
    eDiscountOptionType["CO"] = "CO";
    eDiscountOptionType["FO"] = "FO";
    eDiscountOptionType["FOS"] = "FOS";
})(eDiscountOptionType || (eDiscountOptionType = {}));
var ColumnKPIValue = /** @class */ (function () {
    function ColumnKPIValue(id, valueType, bound) {
        this.columnType = eIndicateurType.kpi;
        this.id = id;
        this.valueType = valueType;
        this.bound = bound;
    }
    return ColumnKPIValue;
}());
export { ColumnKPIValue };
var ColumnKPIDiscount = /** @class */ (function () {
    function ColumnKPIDiscount(id, options) {
        this.columnType = eIndicateurType.discount;
        this.id = id;
        this.options = options;
        this.valueType = (options === null || options === void 0 ? void 0 : options.value) === "Rate" ? eKPIType.Percent : eKPIType.Price;
    }
    return ColumnKPIDiscount;
}());
export { ColumnKPIDiscount };
var ColumnInfo = /** @class */ (function () {
    function ColumnInfo(id) {
        this.columnType = eIndicateurType.info;
        this.id = id;
    }
    return ColumnInfo;
}());
export { ColumnInfo };
export function GetKPIColumns(columns) {
    var cols = [];
    for (var _i = 0, columns_1 = columns; _i < columns_1.length; _i++) {
        var column = columns_1[_i];
        if (column.columnType === eIndicateurType.kpi || column.columnType === eIndicateurType.discount) {
            cols.push(column);
        }
    }
    return cols;
}
var ExportBase = /** @class */ (function () {
    function ExportBase() {
        /**
         * pour les exports
         */
        this.ViewModeExport = "Table";
        this.rowTotal = "bottom";
    }
    return ExportBase;
}());
export { ExportBase };
var AggregateExport = /** @class */ (function (_super) {
    __extends(AggregateExport, _super);
    function AggregateExport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // legacy, old values: "day" | "week"
        _this.timeView = "week_named";
        _this.applySchedulerUserTemplate = true;
        _this.totalDetails = "numeric";
        return _this;
    }
    return AggregateExport;
}(ExportBase));
export { AggregateExport };
export function SanitizeFilter(objectType, filters) {
    return __awaiter(this, void 0, void 0, function () {
        var ignoredProps, userProps, prop;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ignoredProps = ['@rid', 'Active', 'properties', 'CacheInfos'];
                    if (!filters) return [3 /*break*/, 2];
                    return [4 /*yield*/, KPIsManagerCache.GetInstance(objectType).GetUserProperties()];
                case 1:
                    userProps = (_a.sent()).map(function (p) { return p.name; });
                    for (prop in filters) {
                        if (!userProps.includes(prop) && !ignoredProps.includes(prop)) {
                            console.log("[SanitizeFilter]: removing prop ".concat(prop));
                            delete filters[prop];
                        }
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/, filters];
            }
        });
    });
}
var TableExport = /** @class */ (function (_super) {
    __extends(TableExport, _super);
    function TableExport() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TableExport;
}(ExportBase));
export { TableExport };
